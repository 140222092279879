import { Injectable } from '@angular/core';
// import { HTTP } from '@ionic-native/http/ngx';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class GhostService {

  constructor(private http: HttpClient) { }

  getArticles() {
    return this.http.get('/assets/cmr-hiver-2020/ghost-articles.json');
  }
}
